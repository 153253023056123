import React from "react";

type DownloadIconProps = {
  className?: string;
  fill?: string;
  stroke?: string;
};

const DownloadIcon = ({
  className = "",
  fill = "none",
  stroke
}: DownloadIconProps) => (
  <svg viewBox="0 0 64 64" height="26" width="26" className={className}>
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      stroke={stroke}
      fill={fill}
    >
      <path data-cap="butt" d="M32 26v22" />
      <path d="M42 38L32 48 22 38" />
      <path
        data-color="color-2"
        d="M40 52h12c5.6-.5 10-5.3 10-11 0-5.8-4.4-10.5-10.1-11-1-10.1-9.5-18-19.9-18-9 0-16.6 6-19.1 14.2C6.7 27.2 2 32.5 2 39c0 6.8 5.3 12.5 12 13h10"
      />
    </g>
  </svg>
);

export default DownloadIcon;
