import React, { ReactNode } from "react";
import styles from "./QuickLinksBox.module.scss";
import GetText from "../../i18n/GetText";
import AnchorButton from "../../components/AnchorButton/AnchorButton";
import Text from "../Text/Text";
import DownloadIcon from "../../icons/DownloadIcon";

export const DownloadButton = ({
  textId,
  to,
  analyticsDownloadId
}: {
  to: string;
  textId: string;
  analyticsDownloadId: string;
}) => (
  <AnchorButton
    primary={true}
    to={to}
    analyticsDownloadId={analyticsDownloadId}
  >
    <DownloadIcon stroke="#fff" />
    <Text variant="button" style={{ marginInlineStart: "8px" }}>
      <GetText id={textId} />
    </Text>
  </AnchorButton>
);

type QuickLinksBoxProps = {
  title?: ReactNode;
  children: ReactNode;
};

const QuickLinksBox = ({ title, children }: QuickLinksBoxProps) => {
  return (
    <div className={styles.root}>
      {title && <Text variant="h4">{title}</Text>}
      <div className={styles.contents}>{children}</div>
    </div>
  );
};

export default QuickLinksBox;
