import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import QuickLinksBox, {
  DownloadButton
} from "../components/QuickLinksBox/QuickLinksBox";
import Text from "../components/Text/Text";
import GetText from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import ScrollingNavLayout from "../layouts/ScrollingNavLayout/ScrollingNavLayout";
import {
  CommonContext,
  SanityCampaign,
  SanityCreditedDocument,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { teachTopicWordDocUrl } from "../utils/downloads";

export const pageQuery = graphql`
  query TeachPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityTeachPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      right
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 7 })
      metaDescription
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
      slug {
        current
      }
    }
    campaigns: allSanityCampaign(filter: { activeSiteAreas: { in: "teach" } }) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const TeachPage = ({
  pageContext,
  data: { menus, page, campaigns },
  location
}: TeachPageProps) => {
  const { title, _rawContent, translationSponsors, licence, right } = page;
  return (
    <PageLayout
      siteArea={SiteArea.TEACH}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <ScrollingNavLayout
        campaigns={campaigns.nodes}
        title={title}
        content={_rawContent}
        translationSponsors={translationSponsors}
        licence={licence}
        right={
          right === "download" ? (
            <QuickLinksBox title={<GetText id="quick-links" />}>
              <Text variant="defaultLight" gutterBottom>
                <GetText id="download-word" />
              </Text>
              <DownloadButton
                analyticsDownloadId={page.title}
                textId="download"
                to={teachTopicWordDocUrl(page)}
              />
            </QuickLinksBox>
          ) : (
            undefined
          )
        }
      />
    </PageLayout>
  );
};

export default TeachPage;

interface SanityTeachPage extends SanityPage, SanityCreditedDocument {
  _rawContent: SanityRawContent;
  right: "none" | "download" | "campaign";
  slug: {
    current: string;
  };
}

interface TeachPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityTeachPage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
